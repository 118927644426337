@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;900&family=Poppins:wght@300;400;500;600&family=Ubuntu:ital@1&display=swap');


/* An outline is a line that is drawn around elements, OUTSIDE the borders, to make the element "stand out". */

/* box-sizing :
"box-sizing: border-box;" in CSS, it tells the browser to calculate the total 
width and height of an element including the element's padding and border.
 This means that the values you set for the width and height of the element 
 include the padding and border, and the content box of the element will be
  resized to fill the remaining space.
This property is useful when you want to ensure that the total size of an 
element remains consistent, even when you add padding and border to it. */


/* list-style: none; by doing this the bullet item for every list is going to be removed */


/* text-decoration: none; this removes the underline for every link */

/* The html selector is used to apply styles specifically to the root HTML element, while the * selector is used to apply styles to all elements in the document. */

* {
    margin :0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;  
    list-style: none;
    text-decoration: none;
}


/* we are going to have some css varibales, we are going to use css variables throught
 the project, this helps us prevent repeating ourselves
 it is good to have some variables so that we can easily customize our website  */


 /* When we define CSS variables inside the ":root" selector, 
 we create global variables that can be used throughout our CSS file. w ecan easily access
 and modify them from any othr part of our css file */

 /* we define css variable using -- */
 /* "rgba(255, 255, 255, 0.6)", represents a white color with an opacity of 0.6, 
 or 60% transparency. The first three values, "255, 255, 255", represent the red,
  green, and blue values of the color, which are all set to their maximum value of 255,
   resulting in a pure white color. The fourth value, "0.6", represents the alpha value 
   of the color, which is set to 0.6 or 60% opacity. */

   /* the container-wdth is the width of the container class that we 
   are going to rub around different elements. the width of teh container is
   going to change depending on the screen size */



 :root{
    --color-bg: #1f1f38;
    --color-bg: #B99B6B;
    --color-primary: #F2DEBA;
    --color-primary-variant:rgba(104, 74, 40, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    
  

 }

/*  scroll-behavior: smooth; for smoothly scroll to the various sections of the page*/
 html{
    scroll-behavior: smooth;
 }


 /* The ::-webkit-scrollbar pseudo-element in CSS is used to style the scrollbar in 
 webkit-based browsers such as Google Chrome and Safari.
  The display: none property in the rule ::-webkit-scrollbar { display: none; } 
  is used to hide the scrollbar from the user interface. */

 ::-webkit-scrollbar {
    display: none;
 }



 body{

    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-img.jpg);
    
    
 }

/* Photo by <a href="https://unsplash.com/@adrienolichon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Adrien Olichon</a> on <a href="https://unsplash.com/backgrounds/art/texture?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
   */

/* ------------------------- geberal style -------------------------*/



/* margin: 0 auto; to psition container in the middle horizontally */

.container{
    width : var(--container-width-lg);
    margin: 0 auto;

}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}


/* almost all the components ae going to be rubed in a section tag */

section {
    margin-top:  8rem;

}


/* evry section is goingto have an h2 and an h5, here h2 and h5 are the direct child of this section */


/* Using the section > h2 selector instead of section h2 limits the style rules to only
 the <h2> elements that are direct children of a <section> element.
This can be useful if you want to target only the top-level headings within a section,
 while ignoring any subheadings or headings within nested subsections */

section > h2, 
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text_light {
    color: var(--color-light);
}


/* transition: property duration timing-function delay;

each element has a set of properties that define its appearance and behavior, 
such as its size, color, position, font, background, border, and many others.
When any of these properties of an element are changed, either by user interaction, 
JavaScript, or other means, the transition property can be used to apply a smooth 
transition effect to the change, rather than the change happening abruptly.

*/

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

/* width: max-content:  the width of the element will be determined by the width of its content, 
and it will expand or contract as necessary to fit that content without any extra white space or line breaks. */

.btn{
    width: max-content;
    display: inline-block;
    color : var(--color-primary);
    padding:  0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

/* "border-color: transparent;" is a CSS property that sets the color of an element's 
border to be completely transparent, meaning that the border is invisible. */

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}


/* The "object-fit" property specifies how the image should be scaled and positioned 
inside its container, and the "cover" value tells the browser to scale the image so 
that it completely covers the container, while maintaining the aspect ratio of the image.
 This means that the image may be cropped or stretched to fill the entire container, 
 but it will not be distorted or skewed.
 This property is useful for creating responsive and visually appealing designs, 
 especially when dealing with images of different sizes and proportions. 
 By using "object-fit: cover", you can ensure that the image always fills its container,
  without losing its original aspect ratio or clarity 
  
  Note that the "object-fit" property is not supported by all browsers, 
  especially older ones, so it is important to provide fallback options or 
  alternative styles for those cases.*/
img{
    display: block;
    width: 100%;
    object-fit: cover;
}


/* we are going to have some media queries to make it responsive */


/* -----------------------for tablet size/ medium devices---------------------------------- */

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}


/* -----------------------for mobile size/ small devices---------------------------------- */

@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    } 
}