/* The CSS display: grid property defines a container as a grid layout, 
allowing you to create a two-dimensional grid of rows and columns for 
positioning and aligning content within it.

With display: grid, you can easily define a grid structure using the 
grid-template-rows, grid-template-columns, and grid-template-areas 
properties to specify the size and layout of the rows and columns 
in the grid, as well as the areas where the content should be placed.
 */

/* The main difference between grid-template-columns: 35% 50%; and 
grid-template-columns: repeat(3, 1fr); is how the column widths are defined.
grid-template-columns: 35% 50%; sets the width of the first column to 35% of 
the available space in the grid container, and the width of the second column 
to 50% of the available space. This means that the first column will be narrower 
than the second column.
grid-template-columns: repeat(3, 1fr); creates a grid container with three columns 
of equal width, where each column will take up one-third of the available space. 
This means that all three columns will have the same width. 
So, the first example defines the width of each column explicitly, 
while the second example creates a flexible layout where the width 
of each column adjusts automatically based on the available space.
*/

.about__container {

    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
   


}

/* The aspect-ratio property is used in CSS to set the aspect ratio of 
an element's box. In the case of "aspect-ratio: 1/1", it means that the
 width of the box is equal to its height, resulting in a square shape.
 */

.about__me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),transparent);
    display: grid;
    place-items:center;
}


.about__me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition) ;
}

.about__me-image:hover{
    transform: rotate(0);
}


.about__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}


.about__card{

    background: var(--color-bg);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);

}

.about__card:hover {
    background: transparent;
    background-color: var(--color-primary-variant);

}

.about__icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color:var(--color-light)
}

.about__content p{
    margin:  2rem 0 2.6rem;
    color: var(--color-light)

}

/* .about__content a {
    background: var(--color-primary);
    color: var(--color-bg);
    width: max-content;
    display: inline-block;
    color : var(--color-primary);
    padding:  0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
} */


/* -----------------------for tablet size/ medium devices---------------------------------- */

@media screen and (max-width: 1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}



/* -----------------------for mobile size/ small devices---------------------------------- */


@media screen and (max-width: 600px) {
   

    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns:  1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}

