/* The CSS property grid-template-columns specifies the columns of a 
grid container using a space-separated list of values that define
 the size of each column. In this case, the value 1fr 1fr means that 
 the grid container should have two columns, each taking up an equal 
 amount of available space. The 1fr unit is a fractional unit that takes
  up an equal share of the available space, so both columns will be of equal width.
For example, if you had a grid container with a width of 800 pixels, each column
 would be 400 pixels wide since they are taking up an equal amount of space. */

.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    
}


/* .experience__container > div  using this we are getting the direct
divs inside the experience__container 
The CSS selector .experience__container > div refers to
 all the <div> elements that are direct children of an element with a 
 class of "experience__container".*/

.experience__container > div {
    background: var(--color-bg);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color:var(--color-primary-variant);
    cursor: default;
}


.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}


.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
   
}


.experience__Details{
    display: flex;
    gap: 1rem;

}


.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}




/* -----------------------for tablet size/ medium devices---------------------------------- */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content{
    padding: 1rem;
  }
}



/* -----------------------for mobile size/ small devices---------------------------------- */

@media screen and (max-width: 600px) {

    .experience__container {
        gap:1rem;
      }
    
      .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
      }
    
   
}
