
/* transform: translateX(-50%); is a CSS property that moves an element horizontally 
by 50% of its own width to the left. It is used in CSS3 transforms to move an 
element relative to its current position without affecting the document flow or
layout.In this specific case, the element will be moved to the left by an amount 
equal to 50% of its own width. This can be useful when you want to center an 
element horizontally within its parent container, as you can set the left 
position of the element to 50% and then use this transform to move it back 
to the left by half of its own width */


/* backdrop-filter: blur(15px); is a CSS property that applies a blur effect to the background of an element. */


nav{
    background-color: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    /* here the display: flex gap:8.0 will provide space around each icon */
    display: flex;
    gap:0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}


nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color:var(--color-light);
    font-size: 1.1rem;
}

nav a:hover{
    background: rgba(0, 0, 0, 0.3);
}

/* now we want  the nav icon which we selected and be active (the background be darker), 
now we want this to be applied for every icon on which we clicked, 
so we use react hook wghich called usestate */

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);

}