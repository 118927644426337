
/* In CSS, "vh" stands for "viewport height" and it represents a percentage of
 the height of the browser's viewport. For example, 1vh is equal to 1% of the 
 height of the viewport */

 /* overflow: hidden property is used to hide any content that overflows the element's content box. */


header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}


.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}


/* .me{
    width: 30%;
    margin: 0 auto;
} */

.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    /* we want to be 50% from the left, and then subtract from half of the width */
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem;

}

/* -------------------CTA--------------------------- */


/* justify-content: center; is a CSS property that is used in flexbox layout to horizontally align the child elements of a flex container.

When applied to a flex container, it centers the child elements along the horizontal
 axis of the container. This means that the space before the first child and after 
 the last child will be equal, and the child elements will be distributed symmetrically 
 on both sides of the center. */


.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* -----------------------Social----------------------- */



/* When combined, display: flex; and flex-direction: column; 
create a flexible container for laying out elements in a vertical column.
 The child elements of the container will be displayed in a single column 
 and can be aligned and positioned using the flexbox properties such as justify-content,
  align-items, and align-self. */

.header__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

}

/* .header__socials::after { } is a CSS selector that targets a pseudo-element that 
comes after the content of the .header__socials element.
In CSS, pseudo-elements are used to style specific parts of an element that are not 
part of the actual HTML content, such as the first letter of a paragraph, or the 
content that comes before or after an element.
The ::after pseudo-element is used to add content after the content of an element, 
and it can be used to add decorative or functional elements, such as icons, arrows,
 or text. By targeting the ::after pseudo-element of the .header__socials element, 
 you can apply styles specifically to that element. */

.header__social::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ---------------------scroll down----------------------- */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}


/* -----------------------for tablet size/ medium devices---------------------------------- */

@media screen and (max-width: 1024px) {

    header{
        height: 68vh;
    }
  
    
    }


/* -----------------------for mobile size/ small devices---------------------------------- */

@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }

    .header__social, .scroll__down {
        display: none;
    }
}